import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useDocTitle } from '../components/CustomHook';

const Blogs = () => {
    useDocTitle('Alem Immigration Services - Blogs');

    const blogs = [
        {
            id: 1,
            title: "ናይ ክብሪ መዝገብ ምውጻእ ኣገባብ",
            description: "ናይ ክብሪ መዝገብ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: "/images/blog1.jpg",
            date: "March 1, 2024",
            link: "/blog/birth-certificate-process"
        },
        {
            id: 2,
            title: "ናይ መርዓ ሰነድ ምውጻእ",
            description: "ናይ መርዓ ሰነድ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: "/images/blog2.jpg",
            date: "March 5, 2024",
            link: "/blog/marriage-certificate-process"
        },
        {
            id: 3,
            title: "ናይ ቪዛ ምውጻእ ኣገባብ",
            description: "ናይ ቪዛ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: "/images/blog3.jpg",
            date: "March 10, 2024",
            link: "/blog/visa-application-process"
        },
        {
            id: 4,
            title: "ናይ ጥምቀት ወረቐት ምውጻእ",
            description: "ናይ ጥምቀት ወረቐት ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: "/images/blog4.jpg",
            date: "March 15, 2024",
            link: "/blog/baptism-certificate-process"
        },
        {
            id: 5,
            title: "ናይ ትምህርቲ ሰነድ ምውጻእ",
            description: "ናይ ትምህርቲ ሰነድ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: "/images/blog5.jpg",
            date: "March 20, 2024",
            link: "/blog/education-certificate-process"
        }
    ];

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="container mx-auto px-4 py-12">
                <h1 className="text-4xl font-bold text-center text-blue-900 mb-12">Latest Blog Posts</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogs.map((blog) => (
                        <div key={blog.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img 
                                src={blog.image} 
                                alt={blog.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-6">
                                <p className="text-gray-600 text-sm mb-2">{blog.date}</p>
                                <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                                <p className="text-gray-700 mb-4">{blog.description}</p>
                                <Link 
                                    to={blog.link}
                                    className="text-blue-900 hover:text-blue-700 font-semibold"
                                >
                                    Read More →
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Blogs; 