import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const BlogPost = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    useDocTitle(`Alem Immigration Services - Blog Post`);

    // This would typically come from an API or CMS
    const blogPosts = {
        'birth-certificate-process': {
            id: 1,
            title: "ናይ ክብሪ መዝገብ ምውጻእ ኣገባብ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ ወለዲ መንነት ወረቐት</li>
                    <li>ናይ ጥምቀት ወረቐት</li>
                    <li>ናይ ትምህርቲ ሰነድ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ኣብ ቤት ጽሕፈትና ምምጻእ</li>
                    <li>ሰነዳት ምቕራብ</li>
                    <li>ገንዘብ ምኽፋል</li>
                    <li>ምጽባይ</li>
                </ol>
                <p class="mb-4">ናይ ክብሪ መዝገብ ምውጻእ ካብ 3-5 መዓልቲ ይወስድ።</p>
            `,
            date: "March 1, 2024",
            author: "Alem Immigration Services"
        },
        'marriage-certificate-process': {
            id: 2,
            title: "ናይ መርዓ ሰነድ ምውጻእ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ ክልቲኦም መርዓውቲ ክብሪ መዝገብ</li>
                    <li>ናይ ክልቲኦም መርዓውቲ መንነት ወረቐት</li>
                    <li>ናይ ቤተክርስትያን መርዓ ወረቐት</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ምስ ክልቲኦም መርዓውቲ ርክብ ምግባር</li>
                    <li>ሰነዳት ምእካብ</li>
                    <li>ናብ ቤት ፍርዲ ምቕራብ</li>
                    <li>ናይ መወዳእታ ሰነድ ምውሳድ</li>
                </ol>
            `,
            date: "March 5, 2024",
            author: "Alem Immigration Services"
        },
        'visa-application-process': {
            id: 3,
            title: "ናይ ቪዛ ምውጻእ ኣገባብ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ፓስፖርት</li>
                    <li>ናይ ዕድመ ደብዳበ</li>
                    <li>ናይ ባንኪ ሒሳብ</li>
                    <li>ናይ ስራሕ መረጋገጺ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ምምልካት</li>
                    <li>ቃለ መሕትት</li>
                    <li>ውጽኢት ምጽባይ</li>
                </ol>
            `,
            date: "March 10, 2024",
            author: "Alem Immigration Services"
        },
        'baptism-certificate-process': {
            id: 4,
            title: "ናይ ጥምቀት ወረቐት ምውጻእ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ ወለዲ መንነት ወረቐት</li>
                    <li>ናይ ቤተክርስትያን መረጋገጺ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ናብ ቤተክርስትያን ምኻድ</li>
                    <li>መረጋገጺ ምርካብ</li>
                    <li>ሰነድ ምውጻእ</li>
                </ol>
            `,
            date: "March 15, 2024",
            author: "Alem Immigration Services"
        },
        'education-certificate-process': {
            id: 5,
            title: "ናይ ትምህርቲ ሰነድ ምውጻእ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ መንነት ወረቐት</li>
                    <li>ናይ ቤት ትምህርቲ መረጋገጺ</li>
                    <li>ፎቶ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ናብ ቤት ትምህርቲ ምኻድ</li>
                    <li>መረጋገጺ ምርካብ</li>
                    <li>ናብ ሚኒስትሪ ትምህርቲ ምቕራብ</li>
                </ol>
            `,
            date: "March 20, 2024",
            author: "Alem Immigration Services"
        }
    };

    const post = blogPosts[slug];

    if (!post) {
        return (
            <>
                <NavBar />
                <div className="container mx-auto px-4 py-12">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-red-600 mb-4">Blog post not found</h1>
                        <Link to="/blogs" className="text-blue-900 hover:text-blue-700">
                            ← Back to Blogs
                        </Link>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    // Get next and previous post slugs
    const slugs = Object.keys(blogPosts);
    const currentIndex = slugs.indexOf(slug);
    const prevSlug = currentIndex > 0 ? slugs[currentIndex - 1] : null;
    const nextSlug = currentIndex < slugs.length - 1 ? slugs[currentIndex + 1] : null;

    return (
        <>
            <NavBar />
            <div className="container mx-auto px-4 py-12">
                <article className="max-w-3xl mx-auto">
                    <Link to="/blogs" className="text-blue-900 hover:text-blue-700 mb-6 inline-block">
                        ← Back to Blogs
                    </Link>
                    <h1 className="text-4xl font-bold text-blue-900 mb-4">{post.title}</h1>
                    <div className="text-gray-600 mb-8">
                        <span>{post.date}</span> • <span>{post.author}</span>
                    </div>
                    <div 
                        className="prose lg:prose-xl mb-8"
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                    
                    {/* Navigation between posts */}
                    <div className="flex justify-between items-center mt-8 pt-4 border-t">
                        {prevSlug ? (
                            <Link 
                                to={`/blog/${prevSlug}`}
                                className="text-blue-900 hover:text-blue-700"
                            >
                                ← {blogPosts[prevSlug].title}
                            </Link>
                        ) : <div></div>}
                        
                        {nextSlug ? (
                            <Link 
                                to={`/blog/${nextSlug}`}
                                className="text-blue-900 hover:text-blue-700"
                            >
                                {blogPosts[nextSlug].title} →
                            </Link>
                        ) : <div></div>}
                    </div>
                </article>
            </div>
            <Footer />
        </>
    );
};

export default BlogPost; 